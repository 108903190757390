<template>
  <section class="blogs-5">
    <div class="container">
      <div class="row">
        <div v-if="posts[0]" class="col-md-10 mx-auto">
          <h2 class="title mb-3 mt-5">You may also like:</h2>
          <div class="row">
            <div
              v-for="(post, index) in posts"
              :key="index"
              class="col-lg-4 col-md-6"
            >
              <card class="card-blog card-plain">
                <template slot="image">
                  <router-link :to="PostURL(post)">
                    <img class="img rounded" :src="post.feature_image" />
                  </router-link>
                </template>
                <template slot="body">
                  <h4 class="card-title">
                    <router-link :to="PostURL(post)">{{
                      post.title
                    }}</router-link>
                  </h4>
                  <p class="card-description">
                    <span v-html="post.content"></span>...
                    <router-link :to="PostURL(post)">Read More</router-link>
                  </p>
                  <div class="card-footer">
                    <div class="author" style="margin : 0 !important;">
                      <div>
                        <span class="meta">{{
                          DateFromNow(post.created_at)
                        }}</span>
                        <span class="ml-2 mr-2 dot">·</span>
                      </div>
                    </div>
                    <div class="stats stats-right opacity-8">
                      <i class="ni ni-watch-time"></i> {{ post.read_time }} read
                    </div>
                  </div>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Moment from "moment";
export default {
  props: ["posts"],

  methods: {
    PostURL(post) {
      return "/blog/" + post.id + "/" + post.slug;
    },
    DateFromNow(date) {
      // return Moment(date).format("MMM D YYYY");
      return Moment(date).fromNow();
    }
  }
};
</script>
<style></style>
